#main-contact{
    background: rgba(233, 228, 228, 0.199);
}

.cont-cust{
    background: rgba(92, 90, 90, 0.918);
    color: rgb(248, 234, 231);
    padding-bottom: 15px;
    border-color: #2b2523;
    border-style: outset;
    border-radius: 12px;
    border-width: 4px;
    margin-bottom: 15px;
    -webkit-box-shadow-: 1px 2px 4px 1.5px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: 1px 2px 4px 1.5px rgba(255, 255, 255, 0.75);
    box-shadow: 1px 2px 4px 1.5px rgba(81, 124, 81, 0.75);
}

.form-cust{
    background: rgba(233, 228, 228, 0.199);
}

.img-me{
    max-width: 200px;
    height: auto;
    margin-top: 20px;
}

.quick-intro{
    margin-top: 20px;
}

.p-img-div{
    min-width: 225px;
    min-height: 150px;
    max-width: 225px;
    max-height: 150px;
    border: 1px solid black;
    position: relative;
    /* margin-left: auto;
    margin-right: auto; */
}

#my-ports{
    margin-bottom: 15px;
}

.portimage{
    width: 100%;
    height: 100%;
    object-fit: cover;
}    

.portimage, .view-destroyer{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.view-destroyer{
    visibility: hidden;
    background: rgba(0, 0, 0, 0.644);
    z-index: 1;
}

.destroyer-text{
    font-size: 2em;
    color: #fff;
}  

.another-a{
    color: rgb(68, 197, 230);
    text-decoration: underline;
}

.another-a:hover{
    color: rgb(200, 118, 204);
}

.p-about{
    text-indent: 2.5em;
    text-align: left;
    color: #fff;
}

.badge-img{
    max-width: 80%;
    height: auto;
}