.footer{
    position: relative;
    margin-top: -150px;
    height: 150px;
    clear: both;
    padding-top: 5px;
    background: rgba(92, 90, 90, 0.918);
    color: rgb(248, 234, 231);
    border-width: 4px 0 0 0;
    border-style: outset;
    border-color: #2b2523;
    font-size: .75em;
    -webkit-box-shadow-: 4px 4px 4px 6px rgba(231, 203, 247, 0.75);
    -moz-box-shadow-: 4px 4px 4px 6px rgba(231, 203, 247, 0.75);
    box-shadow: 4px 4px 4px 6px rgba(231, 203, 247, 0.75);
}

.aref-img{
    max-height: 50px;
    max-width: 50px;
}

.download-text{
    color: rgb(132, 221, 236);
}

.download-text:hover{
    color: rgb(182, 255, 206);
}

.text-diff{
    color: rgb(182, 255, 206);
    font-weight: bold;
}

#email, #phone{
    padding: 0;
    background-color: inherit;
    cursor: copy;
    border: none;
}

#email{
    min-width: 164px;
}

#phone{
    max-width: 86px;
}