.pskillscroll-container{
    position: relative;
    height: 500px;
    overflow: none;
    z-index: 3;
}

.pskillscroll{
    width: 100%;
    z-index: -2;
    /* background: url('https://www.goodmorningimagesforlover.com/wp-content/uploads/2018/10/dark-side-cool-background-1066x617.jpg') center center / cover no-repeat; */
}

.pskill-elem{
    position: absolute;
    bottom: 0;
    color: black;
    z-index: -1;
}

.pskill-sibling{
    position: relative;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    -webkit-animation: Gradient 30s ease infinite;
    -moz-animation: Gradient 30s ease infinite;
    animation: Gradient 30s ease infinite;
    z-index: -4;
}

.psk-child{
    position: relative;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.733);
    mix-blend-mode: multiply;
    z-index: -3;
}

.pskill-word{
    font-size: 10vw;
    color: white;
    opacity: 1;
    transition: opacity 1.5s;
    -webkit-transition: opacity 1.5s;
    -moz-transition: opacity 1.5s;
}

.pskill-show{
    opacity: 1;
    transition: opacity 1.5s;
    -webkit-transition: opacity 1.5s;
    -moz-transition: opacity 1.5s;
}

.pskill-pic{
    max-height: 50px;
    max-width: auto;
    margin: 5px;
    background: rgba(128, 128, 128, 0.13);
    border: 1px solid transparent;
    z-index: inherit;
}

.pskill-pic:hover{
    background: rgba(93, 131, 95, 0.4);
}

.psk-pic-shower{
    position: relative;
    isolation: isolate;
    max-height: 100px;
    margin: 8px;
    background: rgba(128, 128, 128, 0.13);
}

.psk-pic-shower:hover{
    background: rgba(128, 128, 128, 0.13);
}

@-webkit-keyframes 
    Gradient{
        0%{
            background-position: 0% 50%;
        }
        50%{
            background-position: 100% 50%;
        }
        100%{ background-position: 0% 50%
        }
    }

@-moz-keyframes 
    Gradient{
        0%{
            background-position: 0% 50%;
        }
        50%{
            background-position: 100% 50%;
        }
        100%{
            background-position: 0% 50%;
        }
    }

@keyframes 
    Gradient{
        0%{
            background-position: 0% 50%;
        }
        50%{
            background-position: 100% 50%;
        }
        100%{
            background-position: 0% 50%;
        }
    }