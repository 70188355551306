.top-nav{
    background-color: #2cf68700;
    border-color: #2b2523;
    border-radius: 5px;
    margin: 14px;
    color: rgb(216, 234, 240);
}

.nav-cust {
    background: rgb(92, 90, 90);
    border-color: #2b2523;
    border-style: outset;
    border-radius: 5px;
    padding: 6px;
    margin: 14px;
    border-width: 8px;
    color: rgb(216, 234, 240);
    -webkit-box-shadow: 4px 8px 19px 6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 4px 8px 19px 6px rgba(0, 0, 0, 0.75);
    box-shadow: 4px 8px 19px 6px rgba(0, 0, 0, 0.75);
}

.port-head{
    background: rgba(92, 90, 90, 0.747);
    padding-bottom: 5px;
    margin: 8px 0 8px 0;
    border: 2px solid rgba(92, 248, 227, 0.349);
}

.nav-cust-port{
    background: rgba(80, 86, 97, 0.856);
    border-radius: 8px;
    padding: 6px;
    margin: 14px;
    border-width: 4px;
    color: rgb(216, 234, 240);
    -webkit-box-shadow: 2px 4px 9px 3px rgba(39, 44, 42, 0.5);
    -moz-box-shadow: 2px 4px 9px 3px rgba(39, 44, 42, 0.5);
    box-shadow: 2px 4px 9px 3px rgba(39, 44, 42, 0.5);
}

/* #show-hide{
    overflow: auto;
} */

.btn-in-hiding{
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
}

.input-parent{
    position: relative;
    padding: 0;
    min-width: 150px;
    max-width: 30%;
}

.btn-ports{
    position: absolute;
    z-index: 7;
    background-color: transparent;
    margin-bottom: 10px;
}

.btn-ports:focus{
    background-color: initial;
}

.autocomplete{
    color: silver;
    background-color: white;
    z-index: 1;
}

.btn-acc-holder{
    display: table;
    overflow: auto;
    clear: both;
}

.btn-ports{
    display: table-row;
}

.no-btn{
    color: #fff;
    cursor: default!important;
}

.btn-cust-prof{
    color: #fff;
    background: #333;
}

.or{
    color: black;
}

.inactive{
    color: rgb(255, 255, 255);
}

.inactive:hover{
    border-color: #2b2523;
    color: #aac8d1;
}

#active-cust{
    backface-visibility: hidden;
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    background: rgba(0%, 0%, 0%, 0);
    border-radius: 10px;
    border: 5px solid rgba(92, 248, 227, 0.836);
    border-width: 5px 5px 5px 5px;
    padding: 3px 10px 2px 10px;
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-family: Pretendo sans-serif;
    font-weight: 200;
    font-style: normal;
    letter-spacing: 1px;
}

@media(max-width: 322px){
    .li-item-cust{
        margin-left: auto;
        margin-right: auto;
    }
}